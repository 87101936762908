import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 661.000000 661.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,661.000000) scale(0.100000,-0.100000)">
          <path d="M3454 4869 c-49 -38 -103 -97 -96 -103 3 -3 -3 -8 -12 -12 -9 -3 -16
-14 -16 -23 0 -10 -3 -21 -7 -25 -4 -3 -8 -16 -10 -28 -2 -12 -8 -27 -13 -34
-13 -14 -16 -129 -7 -204 16 -125 157 -452 237 -547 5 -7 10 -17 10 -22 0 -14
31 -67 79 -139 23 -33 41 -66 41 -74 0 -8 5 -19 10 -24 20 -20 26 -136 13
-238 -15 -113 -23 -134 -23 -55 0 30 -3 71 -6 91 -6 32 -9 36 -24 28 -10 -5
-20 -7 -24 -5 -3 3 -2 -1 4 -7 16 -19 11 -96 -10 -182 -16 -62 -18 -87 -10
-116 6 -21 7 -52 3 -71 -8 -39 -74 -108 -130 -134 -21 -10 -40 -21 -43 -25 -3
-3 -32 -22 -65 -40 -33 -19 -61 -37 -63 -42 -2 -4 -8 -8 -12 -8 -8 0 -57 -29
-124 -72 -37 -24 -81 -67 -69 -68 6 0 14 4 17 9 3 5 15 7 26 4 19 -5 19 -6 4
-18 -18 -13 -27 -84 -14 -106 4 -6 4 -26 0 -45 -7 -30 -6 -33 10 -24 30 16 34
11 9 -11 -16 -14 -20 -22 -12 -25 14 -6 12 -10 -45 -57 -19 -15 -29 -27 -24
-27 7 0 272 191 319 230 6 5 76 56 155 114 128 93 255 206 231 206 -5 0 -14
23 -19 50 -10 46 -4 68 10 38 3 -7 2 1 -1 17 -3 17 -11 34 -17 38 -7 6 -4 7 7
3 15 -5 17 3 17 68 0 40 5 98 10 127 6 30 12 87 14 127 3 63 1 76 -17 92 -13
13 -21 35 -23 67 -2 26 -6 64 -10 84 -5 31 -2 38 21 53 21 14 24 20 15 31 -9
11 -8 18 4 30 20 21 20 29 1 21 -17 -6 -21 9 -5 19 8 5 7 12 -2 23 -10 12 -10
16 0 19 6 3 10 9 6 14 -3 5 -1 9 4 9 5 0 9 21 8 48 -1 33 -5 46 -14 44 -8 -1
-11 2 -7 8 3 5 1 10 -4 10 -6 0 8 17 31 38 23 20 45 36 50 34 5 -1 6 2 3 8 -7
11 -25 -1 -120 -74 -22 -17 -57 -44 -77 -59 l-37 -27 -34 58 c-41 66 -125 229
-149 288 -46 113 -62 188 -62 299 -1 130 17 189 88 290 25 36 44 65 42 65 -2
0 -21 -14 -42 -31z"/>
<path d="M3785 3990 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M3465 3799 c-5 -6 -67 -53 -137 -104 -70 -52 -139 -112 -155 -132
-15 -21 -32 -41 -38 -45 -9 -7 18 -35 38 -39 4 -1 10 -2 15 -3 4 -1 26 -6 50
-10 24 -4 42 -10 40 -12 -2 -2 -31 -6 -64 -9 -32 -3 -60 -10 -62 -15 -2 -6
-11 -10 -20 -10 -15 0 -15 -2 -2 -10 8 -5 11 -10 5 -11 -18 -2 49 -17 85 -18
19 0 48 6 64 14 15 8 33 12 39 8 6 -3 7 -1 3 5 -4 6 6 24 21 40 28 29 146 102
164 102 5 0 9 5 9 11 0 5 5 7 12 3 8 -5 9 2 4 27 -4 19 -10 50 -13 69 -5 33
-5 34 10 14 9 -11 19 -33 22 -47 5 -18 14 -27 27 -27 25 0 19 21 -30 104 -20
33 -41 70 -47 80 -12 23 -26 29 -40 15z"/>
<path d="M3800 3765 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"/>
<path d="M2761 3644 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2830 3551 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z"/>
<path d="M3768 3513 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3461 3464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3008 3463 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3216 3325 c-43 -28 -82 -59 -87 -68 -9 -14 -5 -16 23 -11 48 8 138
45 138 56 0 5 5 6 11 3 7 -5 8 5 3 32 -4 21 -7 38 -8 39 0 1 -36 -22 -80 -51z"/>
<path d="M3496 3365 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M3317 3175 c-21 -25 -43 -45 -47 -45 -5 0 -28 -13 -52 -29 -24 -16
-54 -31 -68 -32 -17 -3 -25 -11 -26 -25 -1 -12 -4 -26 -8 -32 -3 -6 0 -13 6
-15 10 -4 10 -6 1 -6 -7 -1 -13 -10 -13 -21 0 -18 -3 -19 -21 -10 -31 17 -34
5 -3 -15 23 -15 32 -16 68 -5 23 7 43 15 46 19 3 4 41 25 85 46 102 51 135 79
135 114 0 28 -36 91 -55 98 -6 2 -27 -17 -48 -42z"/>
<path d="M3771 3024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3761 2985 c0 -5 3 -15 7 -22 5 -9 12 -6 21 10 13 20 13 21 -1 9 -13
-10 -17 -10 -21 0 -3 7 -6 9 -6 3z"/>
<path d="M3066 2665 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M3256 2155 c-7 -20 2 -42 20 -49 12 -4 12 -2 4 15 -9 15 -8 24 1 35
9 11 8 14 -4 14 -8 0 -17 -7 -21 -15z"/>
<path d="M4057 2163 c-3 -5 -8 -28 -12 -53 l-7 -46 67 6 c54 5 71 10 86 29 32
40 27 53 -26 65 l-30 6 28 -15 c53 -30 19 -75 -56 -75 -45 0 -47 1 -47 29 0
20 8 35 22 45 l22 16 -21 0 c-12 0 -24 -3 -26 -7z"/>
<path d="M4620 2144 c-6 -14 -10 -35 -10 -46 0 -19 5 -20 66 -16 68 4 86 16
82 52 -2 11 -9 21 -17 24 -9 3 -11 -2 -6 -17 11 -34 -12 -52 -62 -50 -37 2
-43 6 -43 25 0 28 17 41 60 47 30 3 28 4 -13 5 -42 2 -49 -1 -57 -24z"/>
<path d="M5037 2163 c-2 -5 -7 -25 -11 -45 -7 -37 -6 -38 21 -38 25 1 26 2 11
14 -10 7 -18 21 -18 31 0 10 8 24 18 31 16 12 16 13 0 14 -9 0 -19 -3 -21 -7z"/>
<path d="M4451 2113 c-17 -62 -24 -109 -17 -101 8 7 36 131 31 137 -2 1 -8
-15 -14 -36z"/>
<path d="M4522 2130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2347 2133 c-4 -3 -7 -15 -7 -25 0 -10 -4 -18 -8 -18 -11 0 -44 -71
-40 -85 2 -5 23 -10 47 -10 l43 -2 -6 45 c-3 25 -6 58 -6 73 0 28 -9 36 -23
22z m12 -103 c1 -10 -9 -16 -24 -18 -30 -2 -30 -1 -8 41 15 29 17 30 24 12 4
-11 8 -27 8 -35z"/>
<path d="M4353 2125 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M2766 2123 c-4 -5 -13 -8 -19 -8 -15 0 -16 -27 -2 -32 6 -1 15 8 22
22 12 26 11 30 -1 18z"/>
<path d="M4497 2023 c-10 -40 -17 -80 -16 -90 0 -10 9 18 20 61 11 44 18 85
16 90 -2 6 -11 -21 -20 -61z"/>
<path d="M4341 2073 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M4916 2055 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M4786 2051 c-3 -5 0 -11 8 -14 15 -6 26 1 26 15 0 11 -27 10 -34 -1z"/>
<path d="M4198 2013 c-21 -5 -24 -19 -5 -25 6 -3 20 4 30 14 17 18 11 21 -25
11z"/>
<path d="M4587 1981 c-3 -16 -9 -41 -13 -55 -6 -25 -4 -26 21 -22 36 7 45 16
16 16 -19 0 -22 4 -17 23 3 12 6 28 6 35 0 6 7 12 15 12 8 0 15 5 15 10 0 6
-8 10 -19 10 -12 0 -20 -9 -24 -29z"/>
<path d="M4995 1978 c-4 -18 -9 -41 -11 -50 -4 -12 0 -18 11 -18 12 0 15 6 10
20 -4 11 0 34 9 50 12 24 12 30 1 30 -7 0 -16 -15 -20 -32z"/>
<path d="M2681 1984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4017 1978 c-8 -30 -1 -35 13 -9 13 25 37 27 70 4 l25 -17 -19 22
c-27 31 -81 30 -89 0z"/>
<path d="M4417 1976 c-5 -15 -6 -31 -3 -34 8 -8 18 25 14 45 -3 13 -6 10 -11
-11z"/>
<path d="M4675 1993 c22 -3 44 -8 48 -13 14 -13 -3 -50 -26 -60 -22 -9 -22 -9
-1 -9 23 -1 54 28 54 50 0 27 -23 39 -69 37 l-46 -1 40 -4z"/>
<path d="M3324 1968 c14 -20 14 -24 -1 -40 -15 -17 -15 -18 0 -18 9 0 21 9 27
21 9 16 8 25 -6 40 -24 26 -39 24 -20 -3z"/>
<path d="M4896 1975 c-3 -9 -6 -24 -5 -33 0 -9 5 -4 10 12 9 32 6 48 -5 21z"/>
<path d="M2466 1967 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4321 1954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3220 1920 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2230 1909 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2370 1916 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
